<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          type="text"
          v-model="query.name"
          placeholder="点位名称"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.status"
          placeholder="点位状态"
          @change="getList(1)"
          clearable
        >
          <el-option
            v-for="(item, index) in this.const.POINT_STATUS"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAdd"
        v-auth="this.per.STATION_ADD"
        >添加站点</el-button
      >
    </div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
    >
      <el-table-column prop="name" label="名称" sortable />
      <el-table-column prop="address" label="地址" sortable />
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :target="scope.row.status"
            :list="this.const.POINT_STATUS"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="联系方式" prop="electric"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEdit(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            编辑
          </span>

          <span
            class="option option-danger"
            @click="doDel(scope.row)"
            v-auth="this.per.STATION_UPDATE"
          >
            删除
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitStation"
    ></EditModal>
  </div>
</template>
 
<script>
import { addPoint, fetchPointPage, delPoint, updatePoint } from "@/api/station";
import EditModal from "./component/EditModal";
import MapModal from "./component/MapModal";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";
export default {
  components: { EditModal, MapModal },

  data() {
    return {
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        status: 1,
      },

      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    showMap(e) {
      this.coord = {
        longitude: e.longitude,
        latitude: e.latitude,
      };
      this.modalData = e;
      this.$refs[this.MODAL_KEY.MAP_MODAL].showModal();
    },

    showAdd() {
      this.modalData = {
        stationImg: "",
        providerIds: [],
      };
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEdit(data) {
      this.modalData = {
        ...data,
      };
      this.modalType = "edit";

      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal(this.modalData);
    },

    doDel(row) {
      this.$confirm("是否要删除点位", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delStation({ id: row.id }).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        });
      });
    },

    submitStation(data) {
      let request = updatePoint;
      let msg = "编辑点位成功";
      if (this.validatenull(data.id)) {
        request = addPoint;
        msg = "添加点位成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchPointPage({ ...this.query, ...this.pages }).then((res) => {
         this.dataSource = res.data.data.records || [];
        // this.dataSource = [
        //   {
        //     id: 1,
        //     date: "2016-05-02",
        //     name: "wangxiaohu",
        //     address: "No. 189, Grove St, Los Angeles",
        //   },
        //   {
        //     id: 2,
        //     date: "2016-05-04",
        //     name: "wangxiaohu",
        //     address: "No. 189, Grove St, Los Angeles",
        //   },
        //   {
        //     id: 3,
        //     date: "2016-05-01",
        //     name: "wangxiaohu",
        //     address: "No. 189, Grove St, Los Angeles",
        //     children: [
        //       {
        //         id: 31,
        //         date: "2016-05-01",
        //         name: "wangxiaohu",
        //         address: "No. 189, Grove St, Los Angeles",
        //       },
        //       {
        //         id: 32,
        //         date: "2016-05-01",
        //         name: "wangxiaohu",
        //         address: "No. 189, Grove St, Los Angeles",
        //       },
        //     ],
        //   },
        //   {
        //     id: 4,
        //     date: "2016-05-03",
        //     name: "wangxiaohu",
        //     address: "No. 189, Grove St, Los Angeles",
        //   },
        // ];
        this.total = res.data.data.total;
      });
    },

    resetPage() {
      this.dataSource = [];
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.agentMap = {};
      this.query = {};
    },
  },
};
</script>
