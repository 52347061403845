<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改点位信息' : '添加点位'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="点位提供者" prop="providerIds">
        <el-select
          v-model="providerIds"
          @change="changeProviderIds"
          placeholder="点位提供者"
          clearable
          multiple
        >
          <el-option
            v-for="(item, index) in providerList"
            :key="index"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-collapse v-model="activeName" accordion v-if="providerIds">
        <el-collapse-item
          :title="`${providerSet[item].name}-- 点位费：${providerSet[item].amount}-- 电费：${providerSet[item].electric}`"
          v-for="(item, index) in providerIds"
        >
          <el-form-item label="点位费">
            <el-input
              v-model="providerSet[item].amount"
              placeholder="请输入点位费"
              filterable
            >
            </el-input>
          </el-form-item>

          <el-form-item label="电费">
            <el-input
              v-model="providerSet[item].electric"
              placeholder="请输入电费"
              filterable
            >
            </el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input
              v-model="providerSet[item].remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              placeholder="请输入备注"
            >
            </el-input>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>

      <el-form-item label="点位名称" prop="name">
        <el-input
          v-model="modalData.name"
          placeholder="请输入点位名称"
          filterable
        >
        </el-input>
      </el-form-item>

      <el-form-item label="点位状态" prop="status">
        <el-select
          v-model="modalData.status"
          placeholder="请选择点位状态"
          filterable
        >
          <el-option
            v-for="(item, index) in this.const.POINT_STATUS"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="点位地址" prop="address">
        <el-input
          v-model="modalData.address"
          placeholder="请输入点位地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="经度:" prop="longitude">
        <el-input
          v-model="modalData.longitude"
          placeholder="请输入点位经度"
        ></el-input>
      </el-form-item>

      <el-form-item label="纬度:" prop="latitude">
        <el-input
          v-model="modalData.latitude"
          placeholder="请输入点位纬度"
        ></el-input>
      </el-form-item>

      <el-form-item label="电费：" prop="electric">
        <el-input
          v-model="modalData.num"
          placeholder="请输入电费/度"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="使用日期：" prop="useDate">
        <el-date-picker
          v-model="this.modalData.useDate"
          type="date"
          placeholder="使用日期"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="this.modalData.remark"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="门店照片：" prop="img">
        <UploadImg
          :defaultUrl="modalData.stationImg ? downloadUrl + modalData.img : ''"
          action="/web/admin/file/upload"
          @success="fileUploadSuccess"
        ></UploadImg>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
import { downloadUrl } from "@/config/env";
import { fetchProviderAll } from "@/api/station";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    return {
      downloadUrl: downloadUrl,
      providerSet: {},
      providerIds:[],
      providerList: [],
      formName: "form",
      ruleValidate: {
        name: [
          {
            required: true,
            message: "点位名称不能为空",
            trigger: "change",
          },
        ],

        status: [
          {
            required: true,
            message: "点位状态不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.getProvider();
  },
  methods: {
    // 修改点位提供者
    changeProviderIds(e) {
      e.map((item) => {
        this.providerList.map((provider) => {
          if (provider.id == item && !this.providerSet.hasOwnProperty[item]) {
            this.providerSet[item] = {
              providerId: item,
              name: provider.companyName,
              amount: "",
              remark: "",
              electric: "",
            };
          }
        });
      });
    },

    showModal(data) {
      this.isShow = true;
      // 把pointProviders 的值拆分
      this.providerIds = [];
      this.providerSet = {};
      data.pointProviders &&
      data.pointProviders.map((item) => {
          this.providerList.map((provider) => {
            if (provider.id == item.providerId) {
              this.providerIds.push(item.providerId);
              this.providerSet[item.providerId] = {
                providerId: item.providerId,
                name: provider.companyName,
                amount: item.amount / 100,
                remark: item.remark,
                electric: item.electric,
              };
            }
          });
        });

      if (this.$refs[this.formName]) {
        // 清除校验
        this.$nextTick(() => {
          this.$refs[this.formName].clearValidate();
        });
      }
    },

    getProvider() {
      fetchProviderAll().then((res) => {
        this.providerList = res.data.data;
      });
    },

    //文件上传成功
    fileUploadSuccess(res) {
      this.modalData.img = res;
    },
    changeSign(time) {
      if (!this.validatenull(time)) {
        this.defaultTime = time[1];
      }
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        let providerSet = [];
        this.providerIds.map((item) => {
          if (this.providerSet[item]) {
            providerSet.push(this.providerSet[item]);
          }
        });
        obj.pointProviders = providerSet;
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
